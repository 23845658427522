import { useRoutes } from "react-router-dom";
import { Dashboard } from "../views/dashboard";
import { Profile } from "../views/profile";
import { Test } from "../views/test";
//import { NotFound } from "../views/notFound";

export const Routes = () => {
  const element = useRoutes(routes);
  return element;
};

const routes = [
   {
    element: <Dashboard />,
    path: "/",
  },
  {
    element: <Profile />,
    path: "/profile",
  },
  {
   element: <Test />,
   path: "/test",
  }

  /* { path: "*", element: <NotFound /> }, */
];
