import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {TextInput} from '../../components/TextInput/index.js';
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {getMedicTypes, getMedicProfile, getPublicMedicsFromSearch, sendRumuRequest} from '../../services/';
import styles from './styles.module.scss';
import Calendar from 'react-calendar';
import plusIcon from '../../images/plus-icon.png';
import xButton from '../../images/x-button.png';
import doctor from '../../images/doctor.svg';
import briefcase from '../../images/briefcase.svg';
import bio from '../../images/bio.svg';
import email from '../../images/email.svg';
import location from '../../images/location.svg';
import specialty from '../../images/specialty.svg';
import shape from '../../images/shape.svg';
import phone from '../../images/phone.svg';
import 'react-calendar/dist/Calendar.css';

const LSPECIALTIES = [
  {c:'Orthopaedic', s:'Consultant Orthopaedic Surgeon'},
  {c:'Orthopaedic', s:'Shoulder Specialist'},
  {c:'GP',s:'Rheumatology'},
  {c:'Plastic & Cosmetic',s:'Plastic & Cosmetic'},
  {c:'Hand Surgeon',s:'Hand Surgery'},
  {c:'Psychiatry',s:'Child Psychiatrist'}
]

const LCATEGORIES = [
'GP',
'Orthopaedic',
'Neurology',
'ENT',
'Plastic & Cosmetic',
'Neurosurgeon',
'Psychiatrist',
'Respiratory',
'Dermatology',
'Hand Surgeon',
'Oral & Maxillo Facial',
'Dentist'
]

const TIMES = [
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00'
]

const COUNTIES = [
    'Any County',
    'Antrim',
    'Armagh',
    'Carlow',
    'Cavan',
    'Clare',
    'Cork',
    'Derry',
    'Donegal',
    'Down',
    'Dublin',
    'Fermanagh',
    'Galway',
    'Kerry',
    'Kildare',
    'Kilkenny',
    'Laois',
    'Leitrim',
    'Limerick',
    'Longford',
    'Louth',
    'Mayo',
    'Meath',
    'Monaghan',
    'Offaly',
    'Roscommon',
    'Sligo',
    'Tipperary',
    'Tyrone',
    'Waterford',
    'Westmeath',
    'Wexford',
    'Wicklow'
]


export const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const [setId, id] = useState(null)
  const [medicData, setMedicData] = useState({})
  const [CATEGORIES, setCategories] = useState([])
  const [category, setCategory] = useState(CATEGORIES[0])
  const [SPECIALTIES, setSpecialties] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  const [requestAppointmentOpen, setRequestAppointmentOpen] = useState(false)
  const [files, setFiles] = useState([])
  const [filesUpdated, setFilesUpdated] = useState(false)
  const [recordFiles, setRecordFiles] = useState([])
  const [senderName,setSenderName] = useState('');
  const [senderEmail,setSenderEmail] = useState('');
  const [medicId,setMedicId] = useState('');
  const [senderPhone,setSenderPhone] = useState('');
  const [DOB,setDOB] = useState('');
  const [gpName,setGpName] = useState('');
  const [gpAddress,setGpAddress] = useState('');
  const [clientAddress,setClientAddress] = useState('');
  const [clientSymptoms,setClientSymptoms] = useState('');

  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
      setToken(token);
      const medicId = searchParams.get('id');
      setMedicId(medicId)
      const medicTypes = await getMedicTypes({token})
      const medicData = await getMedicProfile({medicId})

      if(medicData.data.success){
        setMedicData(medicData.data.medic)
        console.log(medicData)
      }else{
        console.log(medicData)
      }

      if(medicTypes.data.success){
        setCategories(medicTypes.data.categories)
        setSpecialties(medicTypes.data.specialties)
        console.log(medicTypes.data.specialties)
      }
      console.log(medicTypes.data)

      setLoading(false);

  }

  useEffect(()=>{
    init()
  },[])

  const uploadFile = async(event)=>{

    let filesList = files;

    for(let index = 0;index < event.target.files.length;index++){
      let file = event.target.files[index];
      filesList.push(file);
    }

    await setFiles(filesList);
    setFilesUpdated(!filesUpdated);
    console.log(files);
    event.target.value = null;
  }

  const uploadRecordFile = async(event)=>{

    let filesList = recordFiles;

    for(let index = 0;index < event.target.files.length;index++){
      let file = event.target.files[index];
      filesList.push(file);
    }

    await setRecordFiles(filesList);
    setFilesUpdated(!filesUpdated);
    event.target.value = null;
  }



  const renderRequestAppointment = ()=>{
    if(!requestAppointmentOpen) return

    return(
      <div className={styles.requestNewProductWindowContainer}>
        <div className={styles.requestNewProductWindow}>
          <img className={styles.requestXButton} src={xButton} onClick={(event)=>{setRequestAppointmentOpen(false)}} />
          <div className={styles.requestRecentBookingsTitleContainer}>
            <div className={styles.requestNewProductHeader}>
              <img className={styles.requestRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.requestRecentBookingsTitleText}>Send Appointment Request</div>
            </div>

            <div className={styles.requestForm2}>
              <div className={styles.requestColumn}>
                <TextInput placeholder="Client Contact Name*" type="form-text" onChange={(value)=>{setSenderName(value)}} initialValue={senderName}/><br/>
                <TextInput placeholder="Client Contact Email*" type="form-text" onChange={(value)=>{setSenderEmail(value)}} initialValue={senderEmail}/><br/>
                <TextInput placeholder="Client Contact Phone*" type="form-text" onChange={(value)=>{setSenderPhone(value)}} initialValue={senderPhone}/><br/>
                <TextInput placeholder="Client DOB*" type="form-text" onChange={(value)=>{setDOB(value)}} initialValue={DOB}/><br/>
                <br/><br/>
                <label for="logoInput">
                  <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="GP Referral File Upload (If Available)" onClick={(event)=>{}} />
                </label>
                <br/><br/>
                <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{uploadFile(event);}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput" name="logoInput" />
                {
                  files.map((file,fileIndex)=>{
                    return(
                      <div className={styles.filesList}>
                        {file.name}
                        <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                          let filesTmp = files;
                          filesTmp.splice(fileIndex,1);
                          await setFiles(filesTmp);
                          setFilesUpdated(!filesUpdated);
                        }} className={styles.removeFileButton} />
                      </div>
                    )
                  })
                }

                <label for="logoInput2">
                  <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Prior Records & Photos" onClick={(event)=>{}} />
                </label>
                <br/><br/>
                <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{uploadRecordFile(event);}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput2" name="logoInput2" />
                {
                  recordFiles.map((file,fileIndex)=>{
                    return(
                      <div className={styles.filesList}>
                        {file.name}
                        <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                          let filesTmp = recordFiles;
                          filesTmp.splice(fileIndex,1);
                          await setRecordFiles(filesTmp);
                          setFilesUpdated(!filesUpdated);
                        }} className={styles.removeFileButton} />
                      </div>
                    )
                  })
                }
              </div>
              <div className={styles.requestColumn}>
                <TextInput placeholder="Client Address*" type="form-text" onChange={(value)=>{setClientAddress(value)}} initialValue={clientAddress}/><br/>
                <TextInput placeholder="Brief Description Of Symptoms*" type="form-text" onChange={(value)=>{setClientSymptoms(value)}} initialValue={clientSymptoms}/><br/>
                
                <TextInput placeholder="Treating GP Name" type="form-text" onChange={(value)=>{setGpName(value)}} initialValue={gpName}/><br/>
                <TextInput placeholder="Treating GP Address" type="form-text" onChange={(value)=>{setGpAddress(value)}} initialValue={gpAddress}/><br/>
                <br/><br/>
                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Send" onClick={async(event)=>{
                  await setLoading(true)
                  let res = await sendRumuRequest({
                    noMedic:false,
                    files:[...recordFiles,...files],
                    gpAddress,
                    gpName,
                    clientSymptoms,
                    clientAddress,
                    senderName,
                    senderPhone,
                    senderEmail,
                    senderDOB:DOB,
                    medicId
                  })

                  setLoading(false)
                  if(res.data.success){
                    setRequestAppointmentOpen(false)
                    alert("Your request was sent.")
                  }else{
                    alert("Your request failed to send. Please try again.")
                  }
                }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={30} color={"#191919"} />
        </div>
      </div>
    )
  }



  return(
    <div className="global-container" style={{overflowY:'auto', background:'#f3f8f7'}}>

          <div className={styles.profileContainer}>
            <div className={styles.menuHeader}>
              
            </div>
            <div className={styles.profileBanner}>
              <div className={styles.profileBannerImage}></div>
              <div className={styles.profileBannerDetails}>
                <img src={medicData.profile || plusIcon} className={styles.profileImage} />
                <div className={styles.profileBannerName}>
                  <img src={doctor} className={styles.doctorIcon} />
                  {medicData.name}
                </div>
              </div>
            </div>
            <div className={styles.profile}>
            <div className={styles.profileContent}>
            <div className={styles.profileHeader}>
                      
                      <div className={styles.contactContainer}>
                        <TextInput style={{marginLeft:'10px', marginRight:'10px', marginTop:'10px'}} border={false} large={true} type="submit-input" icon={plusIcon} placeholder="Request Appointment" onClick={async(event)=>{
                          setRequestAppointmentOpen(true)
                        }} />

                        <a href={"tel:"+medicData.phone}><TextInput style={{marginLeft:'10px',marginRight:'10px', marginTop:'10px'}} border={false} large={true} type="submit-input" icon={phone} placeholder="Call Now" /></a>
                      </div>
                    </div>
                    <div className={styles.resultSubheader}>
                      <div className={styles.resultTable}>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b><img src={email} className={styles.sectionIcon} />Contact Email</b></div><div className={styles.tableColumnRight}>
                      {medicData.contactEmail?(''+medicData.contactEmail):('')}</div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b><img src={shape} className={styles.sectionIcon} />Categories</b></div><div className={styles.tableColumnRight}> {
                        ((medicData.categories || '').split('[****SEP****]')).map((c,ci)=>{
                          if((medicData.categories || '').split('[****SEP****]').length - 1 != ci) return c+', '
                          return c
                        })
                      }<br/></div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b><img src={specialty} className={styles.sectionIcon} />Specialties</b></div><div className={styles.tableColumnRight}> {
                        ((medicData.specialties || '').split('[****SEP****]')).map((c,ci)=>{
                          if((medicData.specialties || '').split('[****SEP****]').length - 1 != ci) return c+', '
                          return c
                        })
                      }</div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b><img src={location} className={styles.sectionIcon} />Clinic Locations</b></div><div className={styles.tableColumnRight}> {

                        ((medicData.county || '').split('[****SEP****]')).map((c,ci)=>{
                          if((medicData.county || '').split('[****SEP****]').length - 1 != ci) return c+', '
                          return c
                        })
                      }</div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b><img src={briefcase} className={styles.sectionIcon} />Qualifications</b></div><div className={styles.tableColumnRight}>{medicData.qualifications || ''}</div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b><img src={bio} className={styles.sectionIcon} />Bio</b></div><div className={styles.tableColumnRight}>{(true)?medicData.bio:(medicData.bio || '').substring(0,30)+"..."}</div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b><img src={bio} className={styles.sectionIcon} />Professional Memberships</b></div><div className={styles.tableColumnRight}>{medicData.professionalMembership || ''}</div></div>
                     
                      </div>
                    </div>

            </div>

            <div className={styles.contact}>
                Book an Appointment  (Coming Soon)
                <br/><br/>
                <TextInput style={{marginRight:'10px'}} placeholder="Clinic Location*" type="dropdown" options={[]}  onChange={(value)=>{
                  setCategory(value)
                }} initialValue={category}/>
                <br/><br/>
                <div className={styles.calendarContainer}>
                  <Calendar onChange={(value)=>{}} value={new Date()} />
                </div>
            </div>
            </div>
            

            </div>

            {
              renderRequestAppointment()
            }
            
        </div>

  )


}
