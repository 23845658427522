import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {TextInput} from '../../components/TextInput/index.js';
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {getMedicTypes, getPublicMedicsFromSearch, sendRequest} from '../../services/';
import styles from './styles.module.scss';

import plusIcon from '../../images/plus-icon.png';
import searchIcon from '../../images/search.svg';
import xButton from '../../images/x-button.png';

const LSPECIALTIES = [
  {c:'Orthopaedic', s:'Consultant Orthopaedic Surgeon'},
  {c:'Orthopaedic', s:'Shoulder Specialist'},
  {c:'GP',s:'Rheumatology'},
  {c:'Plastic & Cosmetic',s:'Plastic & Cosmetic'},
  {c:'Hand Surgeon',s:'Hand Surgery'},
  {c:'Psychiatry',s:'Child Psychiatrist'}
]

const LCATEGORIES = [
'GP',
'Orthopaedic',
'Neurology',
'ENT',
'Plastic & Cosmetic',
'Neurosurgeon',
'Psychiatrist',
'Respiratory',
'Dermatology',
'Hand Surgeon',
'Oral & Maxillo Facial',
'Dentist'
]

const TIMES = [
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00'
]

const COUNTIES = [
    'Any County',
    'Antrim',
    'Armagh',
    'Carlow',
    'Cavan',
    'Clare',
    'Cork',
    'Derry',
    'Donegal',
    'Down',
    'Dublin',
    'Fermanagh',
    'Galway',
    'Kerry',
    'Kildare',
    'Kilkenny',
    'Laois',
    'Leitrim',
    'Limerick',
    'Longford',
    'Louth',
    'Mayo',
    'Meath',
    'Monaghan',
    'Offaly',
    'Roscommon',
    'Sligo',
    'Tipperary',
    'Tyrone',
    'Waterford',
    'Westmeath',
    'Wexford',
    'Wicklow'
]


export const Dashboard = () => {
  const [user, setUser] = useState({});
  const [search,setSearch] = useState('');
  const [error,setError] = useState('');
  const [firmName,setFirmName] = useState('');
  const [senderMessage,setSenderMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const [results, setResults] = useState([]);
  const [specialty, setSpecialty] = useState(null);
  const [category, setCategory] = useState(null);
  const [county, setCounty] = useState(COUNTIES[0]);
  const [chosenMedic, setChosenMedic] = useState(null);
  const navigate = useNavigate();
  const [searched, setSearched] = useState(false)
  const [CATEGORIES, setCategories] = useState([])
  const [SPECIALTIES, setSpecialties] = useState([])
  const [injuredName, setInjuredName] = useState('')
  const [address, setAddress] = useState('')
  const [natureOfReport, setNatureOfReport] = useState('')
  const [specialtyRequired, setSpecialtyRequired] = useState('')
  const [categoryRequired, setCategoryRequired] = useState('')
  const [senderSummary, setSenderSummary] = useState('')
  const [senderParameters, setSenderParameters] = useState('')
  const [files, setFiles] = useState([])
  const [recordFiles, setRecordFiles] = useState([])
  const [filesUpdated, setFilesUpdated] = useState(false)

  const [senderName,setSenderName] = useState('');
  const [senderEmail,setSenderEmail] = useState('');
  const [senderPhone,setSenderPhone] = useState('');
  const [DOB,setDOB] = useState('');
  const [gpName,setGpName] = useState('');
  const [gpAddress,setGpAddress] = useState('');
  const [clientAddress,setClientAddress] = useState('');
  const [clientSymptoms,setClientSymptoms] = useState('');


  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
      setToken(token);
      console.log('medic types')
      const medicTypes = await getMedicTypes({token})

      if(medicTypes.data.success){
        setCategories(medicTypes.data.categories)
        setSpecialties(medicTypes.data.specialties)
        console.log(medicTypes.data.specialties)
      }
      console.log(medicTypes.data)

      setLoading(false);


  }

  const onSubmitSearch = async()=>{
    await setLoading(true)
    const res = await getPublicMedicsFromSearch({category, specialty, county})

    if(res.data.success){
      const filteredCategoryMedics = [...res.data.categoryMedics].filter((m,mi)=>{ return !(m.specialties || '[]').includes(specialty)})
      await setResults([...res.data.availableMedics, ...filteredCategoryMedics].sort((a,b)=>{
        console.log(a)
        if(a.name == 'MedLaw Private Profile' && b.name != 'MedLaw Private Profile') return 1;
        if(a.name != 'MedLaw Private Profile' && b.name == 'MedLaw Private Profile') return -1;
        return 0
      }))
      setSearched(true)
    }else{
      alert('Error getting results.')
    }

    await setLoading(false)
  }

  const sendRequestFunc = async(requestType)=>{
    await setLoading(true)
    let formData = new FormData();

    for(let index = 0; index < files.length;index++){
      formData.append('file'+(index+1), files[index])
    }

    formData.append('county', county)
    formData.append('requestType', requestType)
    formData.append('injuredName', injuredName)
    formData.append('DOB', DOB)
    formData.append('address', address)
    formData.append('natureOfReport', natureOfReport)
    formData.append('specialtyRequired', specialtyRequired)
    formData.append('categoryRequired', categoryRequired)
    formData.append('senderSummary', senderSummary)
    formData.append('senderParameters', senderParameters)
    formData.append('senderName', senderName)
    formData.append('senderEmail', senderEmail)
    formData.append('firmName', firmName)
    formData.append('senderPhone', senderPhone)
    formData.append('senderMessage', senderMessage)
    formData.append('medicId', chosenMedic?chosenMedic.id:null)

    console.log('1')
    const res = await sendRequest(formData)
    console.log(2)
    if(res.data.success){
      await setSenderName('')
      await setSenderParameters('')
      await setSenderMessage('')
      await setChosenMedic(null)
      await setFirmName('')
      await setSenderSummary('')
      await setNatureOfReport('')
      await setAddress('')
      await setDOB('')
      await setInjuredName('')
      await setSenderEmail('')
      setChosenMedic(false)
      setSearched(false)
      alert("Request Sent!")
    }else{
      alert('Error sending request. Please try again.')
    }

    await setLoading(false)
  }


  useEffect(()=>{
    console.log('init')
    init();
  },[]);

  const uploadFile = async(event)=>{

    let filesList = files;

    for(let index = 0;index < event.target.files.length;index++){
      let file = event.target.files[index];
      filesList.push(file);
    }

    await setFiles(filesList);
    setFilesUpdated(!filesUpdated);
    console.log(files);
    event.target.value = null;
  }

  const uploadRecordFile = async(event)=>{

    let filesList = recordFiles;

    for(let index = 0;index < event.target.files.length;index++){
      let file = event.target.files[index];
      filesList.push(file);
    }

    await setRecordFiles(filesList);
    setFilesUpdated(!filesUpdated);
    event.target.value = null;
  }


  const formatDate = (date)=>{
    return date.substr(8,2)+"/"+date.substr(5,2)+"/"+date.substr(0,4);
  }

  const renderChosenMedic = ()=>{
    if(!chosenMedic) return

    return(
      <div className={styles.requestNewProductWindowContainer}>
        <div className={styles.requestNewProductWindow}>
          <img className={styles.requestXButton} src={xButton} onClick={(event)=>{setChosenMedic(null)}} />
          <div className={styles.requestRecentBookingsTitleContainer}>
            <div className={styles.requestNewProductHeader}>
              <img className={styles.requestRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.requestRecentBookingsTitleText}>Send Request To {chosenMedic.name}</div>
            </div>

            <div className={styles.requestForm2}>
              <br/><br/><br/><br/>
              <div className={styles.requestColumn}>
              <TextInput placeholder="Client Contact Name*" type="form-text" onChange={(value)=>{setSenderName(value)}} initialValue={senderName}/><br/>
                <TextInput placeholder="Client Contact Email*" type="form-text" onChange={(value)=>{setSenderEmail(value)}} initialValue={senderEmail}/><br/>
                <TextInput placeholder="Client Contact Phone*" type="form-text" onChange={(value)=>{setSenderPhone(value)}} initialValue={senderPhone}/><br/>
                <TextInput placeholder="Client DOB*" type="form-text" onChange={(value)=>{setDOB(value)}} initialValue={DOB}/><br/>
                <br/><br/>
                <label for="logoInput">
                  <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="GP Referral File Upload (If Available)" onClick={(event)=>{}} />
                </label>
                <br/><br/>
                <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{uploadFile(event);}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput" name="logoInput" />
                {
                  files.map((file,fileIndex)=>{
                    return(
                      <div className={styles.filesList}>
                        {file.name}
                        <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                          let filesTmp = files;
                          filesTmp.splice(fileIndex,1);
                          await setFiles(filesTmp);
                          setFilesUpdated(!filesUpdated);
                        }} className={styles.removeFileButton} />
                      </div>
                    )
                  })
                }

                <label for="logoInput2">
                  <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Prior Records & Photos" onClick={(event)=>{}} />
                </label>
                <br/><br/>
                <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{uploadRecordFile(event);}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput2" name="logoInput2" />
                {
                  recordFiles.map((file,fileIndex)=>{
                    return(
                      <div className={styles.filesList}>
                        {file.name}
                        <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                          let filesTmp = recordFiles;
                          filesTmp.splice(fileIndex,1);
                          await setRecordFiles(filesTmp);
                          setFilesUpdated(!filesUpdated);
                        }} className={styles.removeFileButton} />
                      </div>
                    )
                  })
                }
              </div>
              <div className={styles.requestColumn}>
                <TextInput placeholder="Client Address*" type="form-text" onChange={(value)=>{setClientAddress(value)}} initialValue={clientAddress}/><br/>
                <TextInput placeholder="Brief Description Of Symptoms*" type="form-text" onChange={(value)=>{setClientSymptoms(value)}} initialValue={clientSymptoms}/><br/>
                
                <TextInput placeholder="Treating GP Name" type="form-text" onChange={(value)=>{setGpName(value)}} initialValue={gpName}/><br/>
                <TextInput placeholder="Treating GP Address" type="form-text" onChange={(value)=>{setGpAddress(value)}} initialValue={gpAddress}/><br/>
                <br/><br/>
                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Send" onClick={async(event)=>{
                  
                }} />

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={30} color={"#191919"} />
        </div>
      </div>
    )
  }

  if(searched){
    return(
      <div className={styles.globalContainer}>

          <div className={styles.searchBar}>
            <TextInput style={{marginRight:'10px'}} placeholder="Specialist Category*" type="dropdown" options={CATEGORIES.map((c,ci)=>{ return c.name })}  onChange={(value)=>{
              setCategory(value)
            }} initialValue={category}/>
            <TextInput style={{marginRight:'10px'}} placeholder="Sub-Specialty*" type="dropdown" options={
              SPECIALTIES.filter((s,si)=>{
                return (s.categoryId == CATEGORIES.filter((c,ci)=>{ return c.name == category})[0]?.id)
              }).map((s,si)=>{ return s.name })
            }  onChange={(value)=>{
              setSpecialty(value)
            }} initialValue={specialty}/>
            <TextInput style={{marginRight:'10px'}} placeholder="Specialist County*" type="dropdown" options={COUNTIES}  onChange={(value)=>{
              setCounty(value)
            }} initialValue={county}/>
            <TextInput large={true} type="submit-input" icon={searchIcon} placeholder="Search" onClick={async(event)=>{
              onSubmitSearch()
            }} />
            <img src={require('../../images/logo-main.png')} className={styles.searchLogo} />
          </div>

          <div className={(!(results || {}).length)?styles.results:styles.resultRowContainer}>
            {
              (!(results || {}).length)?
              <div className={styles.searchContainerNoResults}>
                <img src={require('../../images/logo-main.png')} className={styles.medlawLogoMain} />
                <div className={styles.requestForm}>
                Need an assessment before contacting your medic? Rumu can help you get one.
                <br/><br/>
                  <div className={styles.noResults}>
                  <div className={styles.noResultsColumn}>
                  <TextInput style={{marginRight:'10px'}} placeholder="Specialist Category*" type="dropdown" options={CATEGORIES.map((c,ci)=>{ return c.name })}  onChange={(value)=>{
                    setCategory(value)
                  }} initialValue={category}/><br/>
                  <TextInput style={{marginRight:'10px'}} placeholder="Sub-Specialty*" type="dropdown" options={
                    SPECIALTIES.filter((s,si)=>{
                      return (s.categoryId == CATEGORIES.filter((c,ci)=>{ return c.name == category})[0]?.id)
                    }).map((s,si)=>{ return s.name })
                  }  onChange={(value)=>{
                    setSpecialty(value)
                  }} initialValue={specialty}/><br/>
                  <TextInput style={{marginRight:'10px'}} placeholder="Specialist County*" type="dropdown" options={COUNTIES}  onChange={(value)=>{
                    setCounty(value)
                  }} initialValue={county}/><br/>

                  <TextInput placeholder="Brief Description Of Symptoms*" type="form-text" onChange={(value)=>{setClientSymptoms(value)}} initialValue={clientSymptoms}/><br/>
                  <TextInput placeholder="Treating GP Name" type="form-text" onChange={(value)=>{setGpName(value)}} initialValue={gpName}/><br/>
                  <TextInput placeholder="Treating GP Address" type="form-text" onChange={(value)=>{setGpAddress(value)}} initialValue={gpAddress}/><br/>
                  
                  <br/><br/>
                  <label for="logoInput">
                    <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="GP Referral File Upload (If Available)" onClick={(event)=>{}} />
                  </label>
                  <br/><br/>
                  <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{uploadFile(event);}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput" name="logoInput" />
                  {
                    files.map((file,fileIndex)=>{
                      return(
                        <div className={styles.filesList}>
                          {file.name}
                          <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                            let filesTmp = files;
                            filesTmp.splice(fileIndex,1);
                            await setFiles(filesTmp);
                            setFilesUpdated(!filesUpdated);
                          }} className={styles.removeFileButton} />
                        </div>
                      )
                    })
                  }
                  
                  <br/><br/>
                  <label for="logoInput2">
                    <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Prior Records & Photos" onClick={(event)=>{}} />
                  </label>
                  <br/><br/>
                  <input multiple accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(event)=>{uploadRecordFile(event);}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInput2" name="logoInput2" />
                  {
                    recordFiles.map((file,fileIndex)=>{
                      return(
                        <div className={styles.filesList}>
                          {file.name}
                          <img src={require('../../images/remove-icon.png')} onClick={async(event)=>{
                            let filesTmp = recordFiles;
                            filesTmp.splice(fileIndex,1);
                            await setRecordFiles(filesTmp);
                            setFilesUpdated(!filesUpdated);
                          }} className={styles.removeFileButton} />
                        </div>
                      )
                    })
                  }

                  </div>
                  <div className={styles.noResultsColumn}>
                    <TextInput placeholder="Firm Name*" type="form-text" onChange={(value)=>{setFirmName(value)}} initialValue={firmName}/><br/>
                    <TextInput placeholder="Contact Name*" type="form-text" onChange={(value)=>{setSenderName(value)}} initialValue={senderName}/><br/>
                    <TextInput placeholder="Medical expert category required:*" type="form-text" onChange={(value)=>{setCategoryRequired(value)}} initialValue={category}/><br/>
                    <TextInput placeholder="Medical expert specialty required:*" type="form-text" onChange={(value)=>{setSpecialtyRequired(value)}} initialValue={specialty}/><br/>
                    <TextInput placeholder="Email*" type="form-text" onChange={(value)=>{setSenderEmail(value)}} initialValue={senderEmail}/><br/>
                    <TextInput placeholder="Phone*" type="form-text" onChange={(value)=>{setSenderPhone(value)}} initialValue={senderPhone}/><br/>
                    <TextInput placeholder="Enquiry Details*" type="form-textarea" onChange={(value)=>{setSenderMessage(value)}} initialValue={senderMessage}/><br/>
                  </div>
                  </div>

                  <br/>
                  Alternatively you can contact Rumu on info@rumu.ie or 085-1786395
                  <br/><br/>
                  <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Send" onClick={async(event)=>{
                    sendRequestFunc(0)
                  }} />
                </div>
              </div>
              :
              <>
              <div className={styles.resultsColumn}>
              {results.map((r,ri)=>{
                return(
                  <div className={styles.result}>
                    <div className={styles.resultHeader}>
                      <img src={r.profile || require('../../images/medic-profile.png')} className={styles.medicProfile} />
                      <div className={styles.resultName}>
                        {r.name}
                        <div className={styles.resultSpecialty}>{r.specialty}</div>
                      </div>
                      <div className={styles.contactContainer}>
                        <TextInput borrder={true} large={true} type="submit-input" icon={plusIcon} placeholder="Request Appointment" onClick={async(event)=>{
                          setChosenMedic(r)
                        }} />
                      </div>
                    </div>
                    <div className={styles.resultSubheader}>
                      <div className={styles.resultTable}>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b>Contact Email: </b></div><div className={styles.tableColumnRight}>
                      {r.contactEmail?(''+r.contactEmail):('')}</div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b>Categories: </b></div><div className={styles.tableColumnRight}> {
                        ((r.categories || '').split('[****SEP****]')).map((c,ci)=>{
                          if((r.categories || '').split('[****SEP****]').length - 1 != ci) return c+', '
                          return c
                        })
                      }<br/></div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b>Specialties: </b></div><div className={styles.tableColumnRight}> {
                        ((r.specialties || '').split('[****SEP****]')).map((c,ci)=>{
                          if((r.specialties || '').split('[****SEP****]').length - 1 != ci) return c+', '
                          return c
                        })
                      }</div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b>Clinic Locations: </b></div><div className={styles.tableColumnRight}> {
                        r.county
                      }</div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b>Qualifications: </b></div><div className={styles.tableColumnRight}>{r.qualifications || ''}</div></div>
                      <div className={styles.resultTableRow}><div className={styles.tableColumnLeft}><b>Bio: </b></div><div className={styles.tableColumnRight}>{(r.showMore)?r.bio:(r.bio || '').substring(0,30)+"..."}</div></div>
                      </div>
                    </div>


                    <div className={styles.showMoreButton} onClick={(e)=>{
                      document.location = '.profile?id='+r.id 
                      return
                      let tmp = results
                      tmp[ri].showMore = !tmp[ri].showMore
                      setResults([...tmp])
                    }} >{(r.showMore)?"Show Less":"Show More"}</div>

                  </div>
                )
              })
              }

              </div>

              <div className={styles.sideContactMenu}>
                <img src={require('../../images/logo-main.png')} className={styles.medlawLogoMain} />
                <div className={styles.requestForm}>
                Contact Rumu Directly.
                <br/><br/>
                  <TextInput placeholder="Firm Name*" type="form-text" onChange={(value)=>{setFirmName(value)}} initialValue={firmName}/><br/>
                  <TextInput placeholder="Contact Name*" type="form-text" onChange={(value)=>{setSenderName(value)}} initialValue={senderName}/><br/>
                  <TextInput placeholder="Email*" type="form-text" onChange={(value)=>{setSenderEmail(value)}} initialValue={senderEmail}/><br/>
                  <TextInput placeholder="Phone*" type="form-text" onChange={(value)=>{setSenderPhone(value)}} initialValue={senderPhone}/><br/>
                  <TextInput placeholder="Enquiry Details*" type="form-textarea" onChange={(value)=>{setSenderMessage(value)}} initialValue={senderMessage}/><br/>

                  <br/>
                  Alternatively you can contact Rumu
                  <br/>
                  on info@rumu.ie or 085-1786395
                  <br/><br/>
                  <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Send" onClick={async(event)=>{
                    sendRequestFunc(2)
                  }} style={{marginLeft:'0px'}} />
                </div>
              </div>


            </>
            }
          </div>

          {renderChosenMedic()}
      </div>
    )
  }

  return(
    <div className="global-container">
        <div className={styles.searchContainer}>
          
          <div className={styles.medlawSearchDiv}>
            <img src={require('../../images/logo-main.png')} className={styles.medlawLogoMain} />
            <TextInput style={{marginRight:'0px'}} placeholder="Specialist Category*" type="dropdown" options={CATEGORIES.map((c,ci)=>{ return c.name })}  onChange={(value)=>{
              setCategory(value)
              setCategoryRequired(value)
            }} initialValue={category}/>
            <TextInput style={{marginRight:'0px'}} placeholder="Sub-Specialty*" type="dropdown" options={
              SPECIALTIES.filter((s,si)=>{
                return (s.categoryId == CATEGORIES.filter((c,ci)=>{ return c.name == category})[0]?.id)
              }).map((s,si)=>{ return s.name })
            }  onChange={(value)=>{
              setSpecialty(value)
              setSpecialtyRequired(value)
            }} initialValue={specialty}/>
            <TextInput style={{marginRight:'0px'}} placeholder="Specialist County*" type="dropdown" options={COUNTIES}  onChange={(value)=>{
              setCounty(value)
            }} initialValue={county}/>
            <br/><br/><br/>
            <TextInput borrder={true} large={true} type="submit-input" icon={searchIcon} placeholder="Search" onClick={async(event)=>{
              onSubmitSearch()
            }} />
          </div>
        </div>
    </div>
  )


}
